import type { GetServerSideProps, NextPage } from 'next';
import React from 'react';

import { withNonce } from '@thinkaidium/server-security';

export const Index: NextPage = () => <div>Aidium</div>;

export default Index;

export const getServerSideProps: GetServerSideProps = withNonce(async () => ({ props: {} }));
